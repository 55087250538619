import { render, staticRenderFns } from "./InputTextarea.vue?vue&type=template&id=0bf25d97&scoped=true"
import script from "./InputTextarea.vue?vue&type=script&lang=ts"
export * from "./InputTextarea.vue?vue&type=script&lang=ts"
import style0 from "./InputTextarea.vue?vue&type=style&index=0&id=0bf25d97&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf25d97",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/takahiro/development/nola/nola-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0bf25d97')) {
      api.createRecord('0bf25d97', component.options)
    } else {
      api.reload('0bf25d97', component.options)
    }
    module.hot.accept("./InputTextarea.vue?vue&type=template&id=0bf25d97&scoped=true", function () {
      api.rerender('0bf25d97', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/atoms/InputTextarea.vue"
export default component.exports